
































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { deepGet } from '@/utils/ObjectHelpers';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import MapChangeFloorComponent from '@/components/map/MapChangeFloorComponent.vue';
import MapZoomComponent from '@/components/map/MapZoomComponent.vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import Sherpa from '@/utils/map/Sherpa';
import MapEvents from '@/utils/map/MapEvents';
import MapFeature from '@/utils/map/MapFeature';
import FeatureType from '@/utils/map/FeatureType';
import EntityFilterList from '@/utils/types/entity-search/EntityFilterList';
import EntityFilterDate from '@/utils/types/entity-search/EntityFilterDate';
import EntityFilterToggle from '@/utils/types/entity-search/EntityFilterToggle';
import Exhibitor from '@/models/graphql/Exhibitor';
import MapDetailPopupComponent from '@/components/map/MapDetailPopupComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import Geozone from '@/models/graphql/Geozone';
import FloorplanSearchFilterWidget from '@/components/entity-search/FloorplanSearchFilterWidget.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import MapOverviewComponent from '@/components/map/MapOverviewComponent.vue';
import LargeProduct from '@/models/graphql/LargeProduct';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { namespace } from 'vuex-class';
import Decoration from '@/utils/enums/Decoration';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Session from '@/models/graphql/Session';

/* eslint-disable @typescript-eslint/camelcase,max-len,no-underscore-dangle */
declare const window: Window & {
  Sherpa: Sherpa;
};

const exhibitorStore = namespace('ExhibitorStore');
const largeProductStore = namespace('LargeProductStore');
const sessionStore = namespace('SessionStore');

export type HallType = { id: number; name: string; selected: boolean; resultFilterCount: number; foreignId: string };
@Component({
  components: {
    MapOverviewComponent,
    AvatarSoloWidget,
    ButtonIconComponent,
    FontAwesomeComponent,
    FloorplanSearchFilterWidget,
    PillWidget,
    MapDetailPopupComponent,
    PillComponent,
    MapZoomComponent,
    MapChangeFloorComponent,
  },
})
export default class FloorplanComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @largeProductStore.Action
  loadMapLargeProduct!: (payload: {
    geozoneId: number; edition: string; authUser: string;
  }) => Promise<LargeProduct | undefined>;

  @exhibitorStore.Action
  loadMapExhibitor!: (payload: {
    geozoneId: number; edition: string; authUser: string;
  }) => Promise<Exhibitor[]>;

  @sessionStore.Action
  loadMapSession!: (payload: {
    geozoneId: number; edition: string; authUser: string;
  }) => Promise<Session[] | undefined>;

  protected baseStoreName = 'MapWidgetStore';

  @Prop({
    required: false,
    default: null,
  })
  private readonly schemaCode!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly search!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly placeholder!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly url!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly largeProductUrl!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly sessionUrl!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly title!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly backUrl!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly logo!: object;

  @Prop({
    required: false,
    default: null,
  })
  private readonly overview!: Record<string, string | number>;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly halls!: HallType[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly listConfigs!: EntityFilterList[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly dateConfigs!: EntityFilterDate[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly toggleConfigs!: EntityFilterToggle[];

  private markersOn = false;

  private showMobileFilter = false;

  private edition = '';

  private detailBooth!: string;

  private detailHall!: string;

  private detailLoading = true;

  private showDetailPopup = false;

  private selectedFloorId = -1;

  private filterResultText = '';

  private styles: Record<string, string> = {
    left: '10%',
    width: '80%',
  };

  private selectedGeozonesByHallId: Record<string, number[]> = {};

  private detailExhibitorResult: Exhibitor[] = [];

  private detailLargeProductResult: LargeProduct[] = [];

  private detailSessionResult: Session[] = [];

  private roomName = '';

  private isFilterActive = false;

  private mapLoaded = false;

  private mapRendered = false;

  private preventZoomReset = false;

  private selectedFeatureType = '';

  private Decoration = Decoration;

  private FeatureType = FeatureType;

  private FileResourceHelper = FileResourceHelper;

  private get mobileFilter(): boolean {
    return this.isMobile;
  }

  private get exhibitHalls(): HallType[] {
    return this.$store.getters[`${this.widgetStorePath}/exhibitHalls`] || [];
  }

  private get filterLoading(): boolean {
    return this.$store.getters[`${this.widgetStorePath}/isLoading`];
  }

  private get isFloorSelected(): boolean {
    return this.selectedFloorId !== -1;
  }

  private get geozones(): Geozone[] {
    return this.$store.getters[`${this.widgetStorePath}/filteredGeozones`] || [];
  }

  private get selectedHall(): HallType | undefined {
    return this.exhibitHalls.find((f) => f.selected);
  }

  private get detailBaseUrl(): string {
    switch (this.selectedFeatureType) {
      case FeatureType.BOOTH:
        return this.url;
      case FeatureType.LARGE_PRODUCT:
        return this.largeProductUrl;
      case FeatureType.ROOM:
        return this.sessionUrl;
      default:
        return '';
    }
  }

  created(): void {
    this.setDataConfig();
  }

  mounted(): void {
    this.initFloorplan();
  }

  @Watch('isReadyToDisplay')
  initFloorplan(): void {
    if (this.isReadyToDisplay) {
      this.edition = this.schemaCode;
      if (this.exhibitHalls && this.exhibitHalls.length > 0) {
        this.selectedFloorId = this.exhibitHalls[0].id;
      }
      const { query } = this.$route;
      if (!query.edition) {
        Object.assign(query, { edition: this.edition });
      } else {
        this.edition = query.edition as string;
      }
      if (!query.hall) {
        Object.assign(query, { hall: this.selectedFloorId });
      } else {
        this.selectedFloorId = parseInt(query.hall as string, 10);
      }
      this.setExhibitHalls(this.exhibitHalls.map((f) => ({
        ...f,
        name: `map.hall.${f.foreignId.toLowerCase()}`,
        selected: f.id === this.selectedFloorId,
      })));
      this.updateFiltersPosition();
      if (!this.mapRendered) {
        this.renderMap(this.selectedFloorId);
      }
    }
  }

  loadGeozoneIds(): void {
    const {
      filters,
      geozones,
      search,
      toggles,
    } = this.$route.query;
    this.isFilterActive = !!filters || !!search || !!toggles || !!geozones;
    if (this.isFilterActive && this.isReadyToDisplay) {
      this.loadFilteredGeozones()
        .then(() => {
          this.applyFilterResult();
        });
    } else {
      if (!this.mapLoaded) {
        this.renderMap(this.selectedFloorId);
      }
      this.clearGeozonesByHallId();
      this.filterResultText = '';
      this.setExhibitHalls(this.exhibitHalls.map((h) => ({
        ...h,
        resultFilterCount: 0,
      })));
      if (this.markersOn && this.isFloorSelected) {
        window.Sherpa.Map.clearMarkers();
        window.Sherpa.Map.resetZoom();
        this.markersOn = false;
      }
    }
  }

  applyFilterResult(): void {
    this.clearGeozonesByHallId();
    this.exhibitHalls.forEach((h) => {
      Object.assign(this.selectedGeozonesByHallId,
        {
          [h.id]: [],
        });
    });
    this.geozones.forEach((geo) => {
      if (geo
          && geo.id
          && geo.exhibitHall
          && geo.exhibitHall.id
          && this.selectedGeozonesByHallId[geo.exhibitHall.id]) {
        this.selectedGeozonesByHallId[geo.exhibitHall.id].push(geo.id);
      }
    });
    this.setExhibitHalls(
      this.exhibitHalls.map((h) => ({
        ...h,
        resultFilterCount: this.selectedGeozonesByHallId[h.id]
          && this.selectedGeozonesByHallId[h.id].length > 0
          ? this.selectedGeozonesByHallId[h.id].length
          : 0,
      })),
    );
    const halls = Object.keys(this.selectedGeozonesByHallId)
      .filter((k) => this.selectedGeozonesByHallId[k].length > 0);
    if (halls.length > 0
        && this.mapLoaded
        && this.selectedFloorId !== parseInt(halls[0], 10)
        && this.selectedFloorId !== -1) {
      this.switchFloor(parseInt(halls[0], 10));
    }
    if (!this.mapLoaded) {
      this.renderMap(this.selectedFloorId)
        .then(() => {
          this.setFilterResultText();
          if (this.selectedGeozonesByHallId[this.selectedFloorId]) {
            this.addMarkers(this.selectedGeozonesByHallId[this.selectedFloorId]);
          }
        });
    } else {
      this.setFilterResultText();
      if (this.selectedGeozonesByHallId[this.selectedFloorId]) {
        this.addMarkers(this.selectedGeozonesByHallId[this.selectedFloorId]);
      }
    }
  }

  private setExhibitHalls(halls: HallType[]): void {
    return this.$store.commit(`${this.widgetStorePath}/setExhibitHalls`, halls);
  }

  private loadFilteredGeozones(): Promise<void> {
    return this.$store.dispatch(
      `${this.widgetStorePath}/loadFilteredGeozones`,
      Object.keys(this.$route.query).length > 0 ? this.$route.query : {},
    );
  }

  @Watch('$route.query')
  private trigger(): void {
    if ('hall' in this.$route.query) {
      if (this.markersOn) {
        window.Sherpa.Map.clearMarkers();
        window.Sherpa.Map.resetZoom();
        this.markersOn = false;
      }
      this.switchFloor(parseInt(this.$route.query.hall as string, 10));
    }
  }

  private renderMap(floor: number): Promise<void> {
    if (this.edition && floor !== -1) {
      return window.Sherpa.Map.init({
        showCode: this.edition,
        exhibitHallId: floor,
        apiKey: '2fa4231a009e148288114ea5dafc149f',
        target: 'map-canvas',
        viewConfig: {
          enableFeatureHover: false,
          defaultPadding: [0, 0],
          fullscreenControl: false,
          zoomControl: false,
        },
      } as unknown as Record<string, string | object>)
        .then(() => {
          this.mapRendered = true;
          window.Sherpa.Map.on(MapEvents.FEATURE_MOUSE_OVER, (feature: MapFeature) => {
            this.setTooltip(feature);
          });
          window.Sherpa.Map.on(MapEvents.MAP_LOAD, () => {
            window.Sherpa.Map.clearMarkers();
            if (!this.mapLoaded) {
              this.switchFloor(floor);
              this.mapLoaded = true;
            }
            const {
              filters,
              geozones,
              search,
              toggles,
            } = this.$route.query;
            if (!!filters || !!search || !!toggles || !!geozones) {
              this.applyFilterResult();
            }
          });
          window.Sherpa.Map.on(MapEvents.FEATURE_CLICK, (feature: MapFeature) => {
            if (feature
                  && ((feature.kind === FeatureType.BOOTH && feature.exhibitors.length > 0)
                      || (feature.kind === FeatureType.LARGE_PRODUCT && feature.largeProducts.length > 0)
                      || (feature.kind === FeatureType.ROOM))
            ) {
              this.openDetail(feature);
            }
          });

          window.Sherpa.Map.on(MapEvents.MARKER_CLICK, (feature: MapFeature) => {
            if (feature && feature.kind === FeatureType.BOOTH && feature.exhibitors.length > 0) {
              this.openDetail(feature);
            }
          });
          window.Sherpa.Map.on(MapEvents.MAP_CHANGE, () => {
            window.Sherpa.Map.clearMarkers();
            if (this.selectedGeozonesByHallId
                  && this.selectedFloorId > -1
                  && this.selectedGeozonesByHallId[this.selectedFloorId]
                  && this.selectedGeozonesByHallId[this.selectedFloorId].length > 0) {
              this.addMarkers(this.selectedGeozonesByHallId[this.selectedFloorId]);
            }
          });
        });
    }
    return Promise.resolve();
  }

  private clearGeozonesByHallId(): void {
    if (this.selectedGeozonesByHallId
        && Object.keys(this.selectedGeozonesByHallId).length > 0) {
      Object.keys(this.selectedGeozonesByHallId)
        .forEach((k) => {
          this.selectedGeozonesByHallId[k] = [];
        });
    } else {
      this.selectedGeozonesByHallId = {};
    }
  }

  private switchFloor(id: number): void {
    this.selectedFloorId = id;
    this.setExhibitHalls(this.exhibitHalls.map((h) => ({
      ...h,
      selected: h.id === id,
    })));
    if (!this.mapRendered) {
      this.renderMap(id)
        .then(() => {
          if (this.isFilterActive && id !== -1) {
            this.setFilterResultText();
          }
          if (this.mapLoaded && id !== -1) {
            window.Sherpa.Map.clearMarkers();
            window.Sherpa.Map.changeFloor(id);
          }
        });
    } else {
      if (this.isFilterActive && id !== -1) {
        this.setFilterResultText();
      }
      if (this.mapLoaded && id !== -1) {
        window.Sherpa.Map.clearMarkers();
        window.Sherpa.Map.changeFloor(id);
      }
    }
  }

  @Watch('$i18n.locale')
  private setFilterResultText(): void {
    if (this.isFloorSelected) {
      const sum = Object.values(this.selectedGeozonesByHallId)
        .reduce((previousValue, currentValue) => previousValue + currentValue.length, 0);
      if (sum === 0) {
        this.filterResultText = `0 ${this.$t('map.result.found')}`;
      } else if (sum === this.selectedGeozonesByHallId[this.selectedFloorId].length) {
        this.filterResultText = `${this.selectedGeozonesByHallId[this.selectedFloorId].length} ${this.$t('map.result.found')}`;
      } else {
        this.filterResultText = `${this.selectedGeozonesByHallId[this.selectedFloorId].length} ${this.$t('map.result.of')} ${sum} ${this.$t('map.result.text')}`;
      }
    }
  }

  @Watch('windowWidth', { deep: true })
  private updateFiltersPosition(): void {
    this.$nextTick(() => {
      if (!this.isDesktop) {
        this.styles = {
          left: '2.5%',
          width: '95%',
          maxWidth: '100%',
        };
      } else {
        this.styles = {
          left: `${(this.windowWidth.value / 24) * 4}px`,
          width: `${(this.windowWidth.value / 24) * 16}px`,
        };
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private zoomIn(): void {
    window.Sherpa.Map.zoomIn();
  }

  // eslint-disable-next-line class-methods-use-this
  private zoomOut(): void {
    window.Sherpa.Map.zoomOut();
  }

  private setTooltip(feature: MapFeature): void {
    if (feature) {
      if (feature.kind === FeatureType.BOOTH) {
        let text = '';
        if (feature.exhibitors?.length === 0) {
          text = `${this.$t('map.tooltip.empty')}`;
        } else {
          text = feature.exhibitors[0].name
            ? feature.exhibitors[0].name : feature.primaryLabelText;
          if (feature.exhibitors.length > 1) {
            text += ` + ${feature.exhibitors.length - 1} ${this.$t('map.tooltip.more')}`;
          }
        }
        text = text[0].toUpperCase() + text.substring(1, text.length)
          .toLowerCase();
        window.Sherpa.Map.showTooltip(`<p class="paragraph-1 text-left text-white m-0">${text}</p>`);
      } else if (feature.kind === FeatureType.LARGE_PRODUCT) {
        let text = '';
        if (feature.largeProducts?.length === 0) {
          if (feature.exhibitors?.length > 0) {
            text = feature.exhibitors[0].name
              ? feature.exhibitors[0].name : feature.primaryLabelText;
            if (feature.exhibitors.length > 1) {
              text += ` + ${feature.exhibitors.length - 1} ${this.$t('map.tooltip.more')}`;
            }
          } else {
            text = `${this.$t('map.tooltip.empty')}`;
          }
        } else {
          text = feature.largeProducts[0].name
            ? feature.largeProducts[0].name : feature.primaryLabelText;
          if (feature.largeProducts.length > 1) {
            text += ` + ${feature.largeProducts.length - 1} ${this.$t('map.tooltip.more')}`;
          }
        }
        text = text[0].toUpperCase() + text.substring(1, text.length)
          .toLowerCase();
        window.Sherpa.Map.showTooltip(`<p class="paragraph-1 text-left text-white m-0">${text}</p>`);
      } else {
        window.Sherpa.Map.showTooltip(`<p class="paragraph-1 text-left text-white m-0">${feature.id}</p>`);
      }
    }
  }

  private openDetail(feature: MapFeature): void {
    this.selectedFeatureType = feature.kind;
    const geozoneId = feature.id as number;
    this.detailLoading = true;
    this.detailBooth = feature.number;
    this.detailHall = this.exhibitHalls.find((h) => h.selected)?.name || '';
    if (feature.kind === FeatureType.BOOTH) {
      this.showDetailPopup = true;
      this.loadMapExhibitor({
        authUser: this.authUser ? this.authUser.uid : '',
        edition: this.edition,
        geozoneId,
      })
        .then((response) => {
          this.detailExhibitorResult = response || [];
          this.detailLargeProductResult = [];
          this.detailSessionResult = [];
          this.detailLoading = false;
        })
        .catch(() => {
          this.detailLoading = false;
          this.showDetailPopup = false;
        });
    } else if (feature.kind === FeatureType.LARGE_PRODUCT) {
      this.showDetailPopup = true;
      if (feature.largeProducts.length > 0) {
        this.loadMapLargeProduct({
          authUser: this.authUser ? this.authUser.uid : '',
          edition: this.edition,
          geozoneId,
        })
          .then((response) => {
            this.detailExhibitorResult = [];
            this.detailLargeProductResult = response ? [response] : [];
            this.detailLoading = false;
          })
          .catch(() => {
            this.detailLoading = false;
            this.showDetailPopup = false;
          });
      } else if (feature.exhibitors.length > 0) {
        this.loadMapExhibitor({
          authUser: this.authUser ? this.authUser.uid : '',
          edition: this.edition,
          geozoneId,
        })
          .then((response) => {
            this.detailExhibitorResult = response || [];
            this.detailLargeProductResult = [];
            this.detailSessionResult = [];
            this.detailLoading = false;
          })
          .catch(() => {
            this.detailLoading = false;
            this.showDetailPopup = false;
          });
      } else {
        this.detailLoading = false;
        this.showDetailPopup = false;
      }
    } else if (feature.kind === FeatureType.ROOM) {
      this.roomName = feature.primaryLabelText;
      this.showDetailPopup = true;
      this.loadMapSession({
        authUser: this.authUser ? this.authUser.uid : '',
        edition: this.edition,
        geozoneId,
      })
        .then((response) => {
          if (response) {
            this.detailExhibitorResult = [];
            this.detailLargeProductResult = [];
            this.detailSessionResult = response || [];
          }
          this.detailLoading = false;
        })
        .catch(() => {
          this.detailLoading = false;
          this.showDetailPopup = false;
        });
    }
  }

  private clearFilter(): void {
    this.clearGeozonesByHallId();
    this.filterResultText = '';
    this.setExhibitHalls(this.exhibitHalls.map((h) => ({
      ...h,
      resultFilterCount: 0,
    })));
    if (this.markersOn) {
      window.Sherpa.Map.clearMarkers();
      window.Sherpa.Map.resetZoom();
      this.markersOn = false;
    }
    const route = this.$router.resolve({
      path: this.$route.path,
      params: this.$route.params,
      query: this.$route.query,
    }).href;
    window.history.pushState(
      this.$route.query,
      document.title,
      route,
    );
    this.isFilterActive = false;
  }

  private addMarkers(goezoneList: number[]): void {
    if (this.isFloorSelected) {
      if (this.markersOn) {
        window.Sherpa.Map.clearMarkers();
        this.markersOn = false;
      }
      this.markersOn = true;
      if (this.preventZoomReset) {
        window.Sherpa.Map.addMarkers(goezoneList, {
          iconUrl: null,
        });
        this.preventZoomReset = false;
      } else if (!this.preventZoomReset && goezoneList.length === 0) {
        window.Sherpa.Map.resetZoom();
      } else {
        window.Sherpa.Map.addMarkers(goezoneList, {
          zoom: true,
          padding: [200, 200],
          iconUrl: null,
        });
      }
    }
  }

  private handleBaseUrl(): void {
    if (this.backUrl) {
      let r = this.backUrl;
      const matches = this.backUrl.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        this.$router.push(r);
      }
      this.$router.push(r);
    }
  }
}
